import React from 'react';

const PostCard = ({ post }) => {
    console.log(post);
  return (
    <div className="post-box-layout19 figure-scale-animation side-bar-custom-search-width">
      <ul className="entry-meta color-dark-1">
        <li className="post-author">
          <a href={post.authorLink}>
            <img src={post.authorImg} alt="Author" />
            {post.authorName}
          </a>
        </li>
        <li>
          <i className="bi bi-calendar-week" />
          {post.date}
        </li>
        <li>
          <i className="bi bi-clock" />
          {post.readTime}
        </li>
        <li>
          <i className="bi bi-eye" />
          {post.views}
        </li>
      </ul>
      <h3 className="entry-title h3-extra-large color-dark-1 underline-animation">
        <a href={post.postLink} className="link-wrap">
          {post.title}
        </a>
      </h3>
      <div className="video-post-wrap">
        <video
          className="video-box box-border-dark-1 radius-medium"
          id={post.videoSrc}
          playsInline
          controls
        >
          <source src={post.videoSrc} type="video/mp4" />
        </video>
      </div>
      <p className="entry-description color-dark-1">{post.description}</p>
      <div className="post-footer">
        <div className="axil-social social-layout-1 size-small gap-12">
          <ul>
            <li className="facebook">
              <a
                aria-label="Learn more from Facebook"
                href={post.socialLinks.facebook}
              >
                <i className="bi bi-facebook" />
              </a>
            </li>
            <li className="mail-fast">
              <a
                aria-label="Learn more from Mail fast"
                href={post.socialLinks.mailFast}
              >
                <i className="bi bi-envelope" />
              </a>
            </li>
            <li className="pinterest">
              <a
                aria-label="Learn more from Pinterest"
                href={post.socialLinks.pinterest}
              >
                <i className="bi bi-pinterest" />
              </a>
            </li>
          </ul>
        </div>
        <a href={post.postLink} className="btn-text color-dark-1">
          Continue Reading
          <span className="icon-holder">
            <i className="bi bi-arrow-right-short" />
          </span>
        </a>
      </div>
    </div>
  );
};

export default PostCard;
