import React from "react";
import Slider from "react-slick"; // Import react-slick
// import ctgImg1 from '../../assets/category/ctg1.webp';
// import ctgImg2 from '../../assets/category/ctg2.webp';
// import ctgImg3 from '../../assets/category/ctg3.webp';
// import ctgImg11 from '../../assets/category/ctg11.webp';
import bannerImg1 from "../../assets/banner/banner1.webp";
import { Link } from "react-router-dom";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick_next slick-arrow" onClick={onClick}>
      <i class="bi bi-arrow-right"></i>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick_prev slick-arrow" onClick={onClick}>
      <i class="bi bi-arrow-left"></i>
    </div>
  );
}

const CategoryArea = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div>
      <section className="category-wrap-layout-1 space-top-50 bg-color-light-1 transition-default trendingTopic">
        <div className="container">
          <div className="box-border-dark-1 bg-color-scandal padding-29 px-xs-0 radius-default transition-default">
            <div className="row g-3">
              <div className="col-lg-3 col-xxl-2">
                <div className="heading-nav-wrap">
                  <div className="section-heading heading-style-8 color-dark-1-fixed">
                    <h2 className="title h2-small">Trending Topic</h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-xxl-10">
                <Slider {...settings}>
                  {data?.map((item) =>
                    item.recentBlog &&
                    item.recentBlog.bannerImageUrl !== null ? (
                      <div className="single-slide" key={item._id}>
                        <div className="category-box-layout1">
                          <div className="figure-holder">
                            <Link
                              to={`/blog/${item.recentBlog._id}`}
                              className="link-wrap"
                            >
                              <img
                                className="object-fit-cover"
                                width="100"
                                height="100"
                                src={item?.recentBlog?.bannerImageUrl}
                                alt="Category"
                              />
                            </Link>
                          </div>
                          <div className="content-holder">
                            <div className="entry-category style-1 color-dark-1-fixed">
                              <ul>
                                <li>
                                  <Link to={`/blog/${item.recentBlog._id}`}>
                                    {item?.categoryName}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <h3 className="entry-title h3-extra-small color-dark-1-fixed underline-animation">
                              <Link
                                to={`/blog/${item.recentBlog._id}`}
                                className="link-wrap"
                              >
                                {item?.recentBlog?.title}
                              </Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </Slider>{" "}
                {/* End of Slider */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="banner-wrap-layout-1 space-top-50 bg-color-light-1 transition-default">
        <div className="container">
          <div className="banner-box-layout1 box-border-dark-1 radius-default">
            <div className="figure-holder radius-medium">
              <Link to="/" className="link-wrap">
                <img
                  className="w-100"
                  height="230"
                  src={bannerImg1}
                  alt="Banner"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CategoryArea;
