import React from 'react';
import post81 from '../../assets/blog/post81.webp';
import profile2 from '../../assets/blog/profile2.webp';

const BlogPostCard = () => {
  return (
    <div className="post-box-layout19 figure-scale-animation side-bar-custom-search-width">
      <ul className="entry-meta color-dark-1">
        <li className="post-author">
          <a href="author.html">
            <img src={profile2} alt="Author" />
            Ashley Graham
          </a>
        </li>
        <li>
          <i className="bi bi-calendar-week" />
          August 29, 2023
        </li>
        <li>
          <i className="bi bi-clock" />5 min read
        </li>
        <li>
          <i className="bi bi-eye" />
          9k
        </li>
      </ul>
      <h3 className="entry-title h3-extra-large color-dark-1 underline-animation">
        <a href="post-format-default.html" className="link-wrap">
          Underwater Exercise Strengthen Weak
        </a>
      </h3>
      <div className="figure-holder radius-medium box-border-dark-1">
        <a href="post-format-default.html" className="link-wrap img-height-100">
          <img width={810} height={440} src={post81} alt="Post" />
        </a>
      </div>
      <p className="entry-description color-dark-1">
        On August 15th, an alarming email popped up in the inbox of Diana Pearl,
        a New York-based news editor. On August 15th, an alarming email popped.
        The European languages are members of the same family. Their separate
        existence is a myth. For science, music, sport.
      </p>
      <div className="post-footer">
        <div className="axil-social social-layout-1 size-small gap-12">
          <ul>
            <li className="facebook">
              <a
                aria-label="Learn more from Facebook"
                href="https://facebook.com/"
              >
                <i className="bi bi-facebook" />
              </a>
            </li>
            <li className="mail-fast">
              <a
                aria-label="Learn more from Mail fast"
                href="https://mail-fast.com/"
              >
                <i className="bi bi-envelope" />
              </a>
            </li>
            <li className="pinterest">
              <a
                aria-label="Learn more from Pinterest"
                href="https://pinterest.com/"
              >
                <i className="bi bi-pinterest" />
              </a>
            </li>
          </ul>
        </div>
        <a href="post-format-default.html" className="btn-text color-dark-1">
          Continue Reading
          <span className="icon-holder">
            <i className="bi bi-arrow-right-short" />
          </span>
        </a>
      </div>
    </div>
  );
};

export default BlogPostCard;
