import React, { useEffect, useState } from 'react';
import { constant } from '../../../utils/constant';

const topicIconMap = {
  'News & Media Publishers': 'bi-newspaper',
  'Streaming & Online TV': 'bi-tv',
  Sports: 'bi-trophy',
  Marketing: 'bi-bullseye',
  'Entertainment (Bollywood - Ott - Movies)': 'bi-film',
  'Shorts - sarcastic reels - YT redirection': 'bi-camera-video',
  Memes: 'bi-emoji-laughing',
  Trending: 'bi-graph-up-arrow',
};

const RecomendedTopics = () => {
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await fetch(constant.CONST_SARCASM_BLOG_CATEGORY);
        const result = await response.json();
        if (result.success) {
          setTopics(result.data);
        }
      } catch (error) {
        console.error('Error fetching topics:', error);
      }
    };

    fetchTopics();
  }, []);

  return (
    <div>
      <div className="sidebar-widget">
        <div className="section-heading heading-style-6">
          <h3 className="title">Recommended topics</h3>
        </div>
        <div className="widget-tagcloud tagcloud-layout4">
          {topics.map((topic) => (
            <a
              key={topic._id}
              href={`archive-layout1.html?topic=${topic._id}`}
              className="tag-cloud-link box-shadow-small shadow-style-2 box-border-dark-1"
            >
              <span className="icon-holder">
                <i className={topicIconMap[topic.name] || 'bi-tags'} />{' '}
              </span>
              {topic.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecomendedTopics;
