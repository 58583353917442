import React from 'react';
import Slider from 'react-slick';
import profileImg4 from '../../assets/blog/profile4.webp';
import postImg82 from '../../assets/blog/post82.webp';
import postImg81 from '../../assets/blog/post81.webp';
import postImg80 from '../../assets/blog/post80.webp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PostCarouselSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <div><i className="bi bi-arrow-right-short"></i></div>,
    // prevArrow: <div><i className="bi bi-arrow-left-short"></i></div>,
  };

  const posts = [
    {
      title: 'Millions Of Manuscripts Are Written',
      date: 'August 29, 2023',
      readTime: '9 min read',
      views: '9k',
      img: postImg82,
      description:
        'On August 15th, an alarming email popped up in the inbox of Diana Pearl...',
      link: 'post-format-default.html',
    },
    {
      title: 'Underwater Exercise Strengthen Weak',
      date: 'August 29, 2023',
      readTime: '9 min read',
      views: '9k',
      img: postImg81,
      description:
        'On August 15th, an alarming email popped up in the inbox of Diana Pearl...',
      link: 'post-format-default.html',
    },
    {
      title: 'How To Buy Bulb On a Tight Budget',
      date: 'August 29, 2023',
      readTime: '9 min read',
      views: '9k',
      img: postImg80,
      description:
        'On August 15th, an alarming email popped up in the inbox of Diana Pearl...',
      link: 'post-format-default.html',
    },
  ];

  return (
    <div>
      <div className="post-box-layout19 figure-scale-animation position-relative side-bar-custom-search-width">
        <Slider {...settings}>
          {posts.map((post, index) => (
            <div key={index}>
              <ul className="entry-meta color-dark-1">
                <li className="post-author">
                  <a href="author.html">
                    <img src={profileImg4} alt="Author" />
                    Kristin Watson
                  </a>
                </li>
                <li>
                  <i className="bi bi-calendar-week" />
                  {post.date}
                </li>
                <li>
                  <i className="bi bi-clock" />
                  {post.readTime}
                </li>
                <li>
                  <i className="bi bi-eye" />
                  {post.views}
                </li>
              </ul>
              <h3 className="entry-title h3-extra-large color-dark-1 underline-animation">
                <a href={post.link} className="link-wrap">
                  {post.title}
                </a>
              </h3>
              <div className="figure-holder radius-medium box-border-dark-1">
                <a href={post.link} className="link-wrap img-height-100">
                  <img width={810} height={440} src={post.img} alt="Post" />
                </a>
              </div>
              <p className="entry-description color-dark-1">
                {post.description}
              </p>
              <div className="post-footer">
                <div className="axil-social social-layout-1 size-small gap-12">
                  <ul>
                    <li className="facebook">
                      <a href="https://facebook.com/">
                        <i className="bi bi-facebook" />
                      </a>
                    </li>
                    <li className="mail-fast">
                      <a href="https://mail-fast.com/">
                        <i className="bi bi-envelope" />
                      </a>
                    </li>
                    <li className="pinterest">
                      <a href="https://pinterest.com/">
                        <i className="bi bi-pinterest" />
                      </a>
                    </li>
                  </ul>
                </div>
                <a href={post.link} className="btn-text color-dark-1">
                  Continue Reading{' '}
                  <span className="icon-holder">
                    <i className="bi bi-arrow-right-short" />
                  </span>
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PostCarouselSlider;
