import React from 'react'
import elementImg1 from "../../assets/elements/element1.webp"
import elementImg2 from "../../assets/elements/element2.webp"

const NewsLetterEmail = () => {
  return (
    <div>
      <section className="newsletter-wrap-layout1 space-top-60 space-bottom-60 bg-color-light-1 transition-default">
  <div className="container">
    <div className="newsletter-box-layout1 box-border-dark-1 radius-default bg-color-perano">
      <h2 className="entry-title h2-medium f-w-700 color-dark-1-fixed">
        SUBSCRIBE TO OUR NEWSLETTER
      </h2>
      <p className="entry-description color-dark-1-fixed">
        Kurihara Harumi, born March 5, 1947, Shimoda, Shizuoka prefecture, Japan
      </p>
      <form
        action="#"
        className="newsletter-form box-border-dark-1 box-shadow-large shadow-style-2 shadow-fixed transition-default radius-default"
      >
        <input
          type="email"
          className="email-input"
          placeholder="Email Address"
        />
        <button type="submit" className="axil-btn">
          Subscribe
          <i className="bi bi-send-fill" />
        </button>
      </form>
      <ul className="elements-wrap img-height-100">
        <li>
          <img
            width={57}
            height={53}
            src={elementImg1}
            alt="Element"
          />
        </li>
        <li>
          <img
            width={120}
            height={186}
            src={elementImg2}
            alt="Element"
          />
        </li>
      </ul>
    </div>
  </div>
</section>

    </div>
  )
}

export default NewsLetterEmail
