import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';

const Sidebar = ({ toggleSideBar, handleSideBar }) => {
  return (
    <div>
      <div className={`sidebar-left ${toggleSideBar ? 'open' : 'closed'}`}>
        <button className="sidebar-close" onClick={handleSideBar}>
          ×
        </button>
        <nav className="sidebar-nav-left">
          <a
            href="index.html"
            className="link-wrap single-logo light-mode img-height-100 sidebar-left-img"
            aria-label="Site Logo"
          >
            <img width="180" height="46" src={logo} alt="logo" />
          </a>
          <Link to="/" className="sidebar-link-left">
            Home
          </Link>
          <Link to="/about" className="sidebar-link-left">
            About
          </Link>
          <Link to="/contact" className="sidebar-link-left">
            Contact
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
