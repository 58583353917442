import React from 'react';

const BannerWidget = ({ bannerImg }) => {
  return (
    <div>
      <div className="sidebar-widget">
        <div className="widget-banner banner-layout1">
          <div className="radius-default box-border-dark-1 img-height-100">
            <div className="figure-holder radius-medium">
              <img width={700} height={294} src={bannerImg} alt="Banner" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerWidget;
