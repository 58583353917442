const CategoryWidget = () => (
  <div className="sidebar-widget side-bar-custom-search-width">
    <div className="section-heading heading-style-6">
      <h3 className="title">Explore Topics</h3>
    </div>
    <div className="widget-category category-layout1 bg-color-tidal radius-default box-border-dark-1">
      <ul className="category-list">
        <li>
          <a href="archive-layout1.html">Culture</a>(2)
        </li>
        <li>
          <a href="archive-layout1.html">Travel</a>(1)
        </li>
        <li>
          <a href="archive-layout1.html">Business</a>(5)
        </li>
        <li>
          <a href="archive-layout1.html">Trending</a>(4)
        </li>
      </ul>
    </div>
  </div>
);

export default CategoryWidget;
