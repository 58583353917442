import React, { useState, useEffect, useRef } from 'react';
import '../../app.css';
import logoPng from '../../assets/logo.png';
import MobileMenu from './MobileMenu';
import HeaderActions from './HeaderActions';
import { Link } from 'react-router-dom';

const Header = ({handleSideBar}) => {
  const [theme, setTheme] = useState('light');
  const [isSticky, setIsSticky] = useState(false);

  const menuRef = useRef(null);
  const stickyPlaceholderRef = useRef(null);

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);
  useEffect(() => {
    const handleScroll = () => {
      const menu = menuRef.current;
      const topbarHeight =
        document.getElementById('topbar-wrap')?.offsetHeight || 0;
      const targetScroll = topbarHeight;

      if (window.scrollY > targetScroll) {
        setIsSticky(true);
        if (menu && stickyPlaceholderRef.current) {
          stickyPlaceholderRef.current.style.height = `${menu.offsetHeight}px`;
        }
      } else {
        setIsSticky(false);
        if (stickyPlaceholderRef.current) {
          stickyPlaceholderRef.current.style.height = '0';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <header className={`header header1 sticky-on ${isSticky ? 'sticky' : ''}`}>
      <div id="topbar-wrap">
        <div className="topbar-global color-dark-1-fixed">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between gap-1">
              <div className="news-feed-wrap">
                <div className="news-feed-label">BREAKING:</div>
                <div
                  id="news-feed-slider"
                  className="news-feed-slider initially-none"
                >
                  <div className="single-slide">
                    <a href="post-format-default.html" className="link-wrap">
                      Underwater Exercise Is Used Weak Of Muscles Thats Good
                    </a>
                  </div>
                  <div className="single-slide">
                    <a href="post-format-default.html" className="link-wrap">
                      Tesla’s Cooking Up A New Way To Wire
                    </a>
                  </div>
                  <div className="single-slide">
                    <a href="post-format-default.html" className="link-wrap">
                      50 Years After The Moon Landing: How Close
                    </a>
                  </div>
                  <div className="single-slide">
                    <a href="post-format-default.html" className="link-wrap">
                      Millions Of Manuscripts Are Written
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4">
                <div className="current-date d-lg-block d-none">
                  July 5, 2023
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div className="d-lg-block d-none">
                    <div className="my_switcher">
                      <ul>
                        <li title="Light Mode">
                          <button
                            type="button"
                            aria-label="Light"
                            className="setColor light"
                            data-theme="light"
                            onClick={() => toggleTheme('light')}
                          >
                            <i className="bi bi-sun"></i>
                          </button>
                        </li>
                        {/* Additional theme buttons */}
                      </ul>
                    </div>
                  </div>
                  {/* Notification & other items
                  <div className="mobile-menu-btn d-lg-none d-block">
                    <button
                      aria-label="Offcanvas"
                      className="btn-wrap"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#mobilemenu-popup"
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sticky-placeholder" ref={stickyPlaceholderRef}></div>
      <div id="navbar-wrap" ref={menuRef} className="navbar-wrap">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-md-block d-none">
              <Link
                to="/"
                className="link-wrap desktop-logo img-height-100"
                aria-label="Site Logo"
              >
                <img width="180" height="46" src={logoPng} alt="logo" />
              </Link>
            </div>
            <div className="d-md-none d-block">
              <a
                href="index.html"
                className="link-wrap mobile-logo img-height-100"
                aria-label="Site Logo"
              >
                <img
                  width="170"
                  height="42"
                  src={logoPng}
                  alt="logo"
                />
              </a>
            </div>
            <MobileMenu />
            <HeaderActions handleSideBar ={handleSideBar}/>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
