import React from 'react';

const SearchWidget = () => {
  return (
    <div>
      <div className="sidebar-widget side-bar-custom-search-width">
        <div className="widget-search search-layout1 radius-default padding-40 box-border-dark-1">
          <h3 className="title h3-medium">Search</h3>
          <p className="description">Type here &amp; press enter</p>
          <form>
            <button aria-label="Search" type="button" className="icon-holder">
              <i className="bi bi-search" />
            </button>
            <input type="text" className="form-control" placeholder="Search" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchWidget;
