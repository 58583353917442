import React from 'react';
import profileImg4 from '../../assets/blog/profile4.webp';

const BlogPostPreview = () => {
  return (
    <div className="post-box-layout19 figure-scale-animation side-bar-custom-search-width">
      <ul className="entry-meta color-dark-1">
        <li className="post-author">
          <a href="author.html">
            <img src={profileImg4} alt="Author" />
            Kristin Watson
          </a>
        </li>
        <li>
          <i className="bi bi-calendar-week" />
          August 29, 2023
        </li>
        <li>
          <i className="bi bi-clock" /> 9 min read
        </li>
        <li>
          <i className="bi bi-eye" />
          9k
        </li>
      </ul>
      <h3 className="entry-title h3-extra-large color-dark-1 underline-animation">
        <a href="post-format-default.html" className="link-wrap">
          Millions Of Manuscripts Are Written
        </a>
      </h3>
      <div className="audio-post-wrap box-border-dark-1 radius-medium">
        <iframe
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/184476938&color=%23ff2c54&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        />
      </div>
      <p className="entry-description color-dark-1">
        On August 15th, an alarming email popped up in the inbox of Diana Pearl,
        a New York-based news editor. On August 15th, an alarming email popped.
        The European languages are members of the same family. Their separate
        existence is a myth. For science, music, sport.
      </p>
      <div className="post-footer">
        <div className="axil-social social-layout-1 size-small gap-12">
          <ul>
            <li className="facebook">
              <a
                aria-label="Learn more from Facebook"
                href="https://facebook.com/"
              >
                <i className="bi bi-facebook" />
              </a>
            </li>
            <li className="mail-fast">
              <a
                aria-label="Learn more from Mail fast"
                href="https://mail-fast.com/"
              >
                <i className="bi bi-envelope" />
              </a>
            </li>
            <li className="pinterest">
              <a
                aria-label="Learn more from Pinterest"
                href="https://pinterest.com/"
              >
                <i className="bi bi-pinterest" />
              </a>
            </li>
          </ul>
        </div>
        <a href="post-format-default.html" className="btn-text color-dark-1">
          Continue Reading
          <span className="icon-holder">
            <i className="bi bi-arrow-right-short" />
          </span>
        </a>
      </div>
    </div>
  );
};

export default BlogPostPreview;
