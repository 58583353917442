import React, { useEffect, useState } from 'react';
import '../../app.css';
// assets/media/blog/post112.webp"
// import postImg13 from '../../assets/blog/post13.webp';
import postImg23 from '../../assets/blog/post23.webp';
import postImg14 from '../../assets/blog/post14.webp';
import bannnerImg2 from '../../assets/banner/banner2.webp';
import bannnerImg3 from '../../assets/banner/banner3.webp';
import postImg15 from '../../assets/blog/post15.webp';
import postImg16 from '../../assets/blog/post16.webp';
// import BlogPostDetails from './BlogPostDetails';
// import BlogArticleContent from './BlogArticleContent';
// import BlogPostLayout from './BlogPostLayout';
// import TagShareSection from './TagShareSection';
// import AuthorBio from './AuthorBio';
// import PostNavigation from './PostNavigation';
// import CommentSection from './CommentSection';
import BannerWidget from './blogsidebar/BannerWidget';
import SearchWidget from './blogsidebar/SearchWidget';
import CategoryWidget from './blogsidebar/CategoryWidget';
import BlogBody from './BlogBody';
import RecomendedTopics from './blogsidebar/RecomendedTopics';
import { useParams } from 'react-router-dom';
import { constant } from '../../utils/constant';

const Blog = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();

  const fetchData = async () => {
    try {
      const response = await fetch(`${constant.CONST_SARCASM_BLOG}/${id}`);
      const json = await response.json();
      if (json.success) {
        setData(json.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <section className="space-top-60 space-bottom-60 single-blog-wrap1 bg-color-light-1 transition-default">
        <div className="container">
          <div className="row sticky-coloum-wrap">
            <div className="col-lg-8 sticky-coloum-item">
              <div className="single-blog-content content-layout1 pe-lg-4">
                {/* <BlogPostLayout /> */}
                {/* <BlogArticleContent /> */}
                {/* <TagShareSection /> */}
                {/* <AuthorBio />
                <PostNavigation />
                <CommentSection /> */}
                <BlogBody data={data} />
                {/* -------------------------------------------- */}
              </div>
            </div>
            <div className="col-lg-4 sticky-coloum-item">
              <div className="sidebar-global sidebar-layout4">
                <BannerWidget bannerImg={bannnerImg2} />
                <SearchWidget />
                <CategoryWidget />
                <div className="sidebar-widget">
                  <div className="section-heading heading-style-6">
                    <h3 className="title">Short Stories</h3>
                  </div>
                  <div className="widget-post post-layout1">
                    <div className="post-box">
                      <div className="figure-holder radius-default">
                        <a
                          href="post-format-default.html"
                          className="link-wrap figure-overlay img-height-100"
                        >
                          <img
                            width={700}
                            height={470}
                            src={postImg23}
                            alt="Post"
                          />
                        </a>
                      </div>
                      <div className="content-holder">
                        <h3 className="entry-title color-light-1-fixed h3-small underline-animation">
                          <a
                            href="post-format-default.html"
                            className="link-wrap"
                          >
                            Top 5 Street Tacos In Usa Top 5 Street Tacos In Usa
                          </a>
                        </h3>
                        <ul className="entry-meta color-light-1-fixed">
                          <li>
                            <i className="bi bi-clock" />2 min read
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="post-box">
                      <div className="figure-holder radius-default">
                        <a
                          href="post-format-default.html"
                          className="link-wrap figure-overlay img-height-100"
                        >
                          <img
                            width={140}
                            height={140}
                            src={postImg14}
                            alt="Post"
                          />
                        </a>
                      </div>
                      <div className="content-holder">
                        <h3 className="entry-title color-dark-1 underline-animation h3-extra-small">
                          <a
                            href="post-format-default.html"
                            className="link-wrap"
                          >
                            Smarter Food 101 Tips For Your Health
                          </a>
                        </h3>
                        <ul className="entry-meta color-dark-1">
                          <li>
                            <i className="bi bi-clock" />2 min read
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="post-box">
                      <div className="figure-holder radius-default">
                        <a
                          href="post-format-default.html"
                          className="link-wrap figure-overlay img-height-100"
                        >
                          <img
                            width={140}
                            height={140}
                            src={postImg15}
                            alt="Post"
                          />
                        </a>
                      </div>
                      <div className="content-holder">
                        <h3 className="entry-title color-dark-1 underline-animation h3-extra-small">
                          <a
                            href="post-format-default.html"
                            className="link-wrap"
                          >
                            Virtual Reality (VR), The Use Computer Modeling
                          </a>
                        </h3>
                        <ul className="entry-meta color-dark-1">
                          <li>
                            <i className="bi bi-clock" />8 min read
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="post-box">
                      <div className="figure-holder radius-default">
                        <a
                          href="post-format-default.html"
                          className="link-wrap figure-overlay img-height-100"
                        >
                          <img
                            width={140}
                            height={140}
                            src={postImg16}
                            alt="Post"
                          />
                        </a>
                      </div>
                      <div className="content-holder">
                        <h3 className="entry-title color-dark-1 underline-animation h3-extra-small">
                          <a
                            href="post-format-default.html"
                            className="link-wrap"
                          >
                            Reality Hosting, The Use Computer Modeling
                          </a>
                        </h3>
                        <ul className="entry-meta color-dark-1">
                          <li>
                            <i className="bi bi-clock" />5 min read
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sidebar-widget">
                  <div className="widget-banner banner-layout2">
                    <div className="figure-holder radius-default box-border-dark-1">
                      <a href="#" className="link-wrap img-height-100">
                        <img
                          width={700}
                          height={772}
                          src={bannnerImg3}
                          alt="Banner"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="sidebar-widget">
                  <div className="widget-newsletter newsletter-layout1 box-border-dark-1">
                    <h2 className="title">NEWSLETTER THAT MAKES YOU HUNGRY!</h2>
                    <div className="sub-title">Sign Up for free</div>
                    <form action="#" className="newsletter-form">
                      <input
                        type="email"
                        className="email-input"
                        placeholder="Email Address"
                      />
                      <button
                        type="submit"
                        className="axil-btn axil-btn-fill axil-btn-dark-fixed w-100 axil-btn-bold"
                      >
                        Subscribe
                        <i className="bi bi-send" />
                      </button>
                    </form>
                  </div>
                </div>
                <RecomendedTopics />
                <div className="sidebar-widget side-bar-custom-search-width">
                  <div className="widget-follow follow-layout1 radius-default padding-40 box-border-dark-1">
                    <h3 className="title h3-medium">Follow Us</h3>
                    <p className="description">Follow us on Social Network</p>
                    <div className="axil-social social-layout-1 size-small gap-12 justify-content-center">
                      <ul>
                        <li className="facebook">
                          <a href="#" aria-label="Learn more from Facebook">
                            <i className="bi bi-facebook" />
                          </a>
                        </li>
                        <li className="instagram">
                          <a href="#" aria-label="Learn more from Instagram">
                            <i className="bi bi-instagram" />
                          </a>
                        </li>
                        <li className="mail-fast">
                          <a aria-label="Learn more from Mail fast" href="#">
                            <i className="bi bi-envelope" />
                          </a>
                        </li>
                        <li className="pinterest">
                          <a href="#" aria-label="Learn more from Pinterest">
                            <i className="bi bi-pinterest" />
                          </a>
                        </li>
                        <li className="youtube">
                          <a href="#" aria-label="Learn more from Youtube">
                            <i className="bi bi-youtube" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
