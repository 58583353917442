import React from 'react';
import aboutImg1 from '../../assets/about/about1.webp';
import aboutImg2 from '../../assets/about/about2.webp';
import aboutImg3 from '../../assets/about/about3.webp';

const About = () => {
  return (
    <div>
      <section className="about-wrap-layout-1 space-top-60 space-bottom-60 space-bottom-md-30 bg-color-selago transition-default">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-6 order-lg-2">
              <div className="about-box-layout-1 box-border-dark-1-fixed radius-default">
                <div className="figure-holder radius-medium img-height-100">
                  <img width="700" height="566" src={aboutImg2} alt="ABOUT" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="about-box-layout-1 pe-lg-5">
                <div className="content-holder">
                  <h2 className="entry-title color-dark-1-fixed">Our Story</h2>
                  <p className="entry-description color-dark-1-fixed">
                    On August 15th, an alarming email popped up in the inbox of
                    Diana Pearl...
                  </p>
                  <p className="entry-description color-dark-1-fixed">
                    Notes generate random lorem text when needed...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-wrap-layout-1 space-top-60 space-bottom-60 space-bottom-md-30 bg-color-light-1 transition-default">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-6">
              <div className="about-box-layout-1 box-border-dark-1 radius-default">
                <div className="figure-holder radius-medium img-height-100">
                  <img width="700" height="566" src={aboutImg1} alt="ABOUT" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-box-layout-1">
                <div className="content-holder">
                  <h2 className="title">
                    The Professional Publishing Platform
                  </h2>
                  <p className="description">
                    Professional context it often happens that private or
                    corporate clients...
                  </p>
                  <ul className="list-style-1">
                    <li>Leave extra space between you and other cars</li>
                    <li>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text
                    </li>
                    <li>
                      Many variations of passages of Lorem Ipsum available
                    </li>
                    <li>
                      Lorem Ipsum comes from sections of "de Finibus Bonorum"
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-wrap-layout-2 space-top-60 space-bottom-60 space-bottom-md-30 bg-color-light-2">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-6 order-lg-2">
              <div className="about-box-layout-1 box-border-dark-1 radius-default">
                <div className="figure-holder radius-medium img-height-100">
                  <a
                    href="https://www.youtube.com/watch?v=1iIZeIy7TqM"
                    aria-label="Youtube Video"
                    className="play-btn style-2 size-regular popup-youtube"
                  >
                    <i className="bi bi-play-fill"></i>
                  </a>
                  <img width="700" height="566" src={aboutImg3} alt="ABOUT" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="about-box-layout-1">
                <div className="content-holder">
                  <h2>Our Growing News Network</h2>
                  <p>
                    Cicero famously orated against his political opponent Lucius
                    Sergius Catilina...
                  </p>
                  <h3 className="h3-medium mb-2">Network Terms:</h3>
                  <p>
                    Most text editors like MS Word or Lotus Notes generate
                    random lorem text...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="team-wrap-layout2 space-top-50 bg-color-light-1 transition-default">
        <div className="container">
          <div className="section-heading heading-style-10">
            <h2 className="title">Meet Our Team</h2>
            <p className="description">
              Most text editors like MS Word or Lotus Notes generate random
              lorem text when needed...
            </p>
          </div>
          <div className="row g-3">
            {teamMembers.map((member, index) => (
              <div className="col-md-4" key={index}>
                <div className="team-layout-2">
                  <div className="figure-holder">
                    <a href="author.html" className="link-wrap img-height-100">
                      <img
                        width="520"
                        height="520"
                        src={member.image}
                        alt="Team"
                      />
                    </a>
                  </div>
                  <div className="content-holder">
                    <div>
                      <h3 className="entry-title h3-medium">
                        <a href="author.html" className="link-wrap">
                          {member.name}
                        </a>
                      </h3>
                      <div className="entry-designation text-b4 f-w-500">
                        {member.designation}
                      </div>
                    </div>
                    <div className="axil-social social-layout-1 size-medium gap-8">
                      <ul>
                        {member.social.map((social, i) => (
                          <li key={i} className={social.platform}>
                            <a
                              aria-label={`Learn more from ${social.platform}`}
                              href={social.link}
                            >
                              <i className={`bi bi-${social.platform}`}></i>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

const teamMembers = [
  {
    name: 'Georges Embolo',
    designation: 'Publisher',
    image: '../../assets/team/team4.webp',
    social: [
      { platform: 'pinterest', link: '#' },
      { platform: 'twitter', link: '#' },
      { platform: 'mail-fast', link: '#' },
    ],
  },
  {
    name: 'Nayah Tantoh',
    designation: 'Publisher',
    image: '../../assets/team/team5.webp',
    social: [
      { platform: 'pinterest', link: '#' },
      { platform: 'twitter', link: '#' },
      { platform: 'mail-fast', link: '#' },
    ],
  },
  {
    name: 'John Philipe',
    designation: 'Publisher',
    image: 'a../../assets/team/team6.webp',
    social: [
      { platform: 'pinterest', link: '#' },
      { platform: 'twitter', link: '#' },
      { platform: 'mail-fast', link: '#' },
    ],
  },
];

export default About;
