import React, { useState } from 'react';
import Header from './components/header/Header';
import HeroBody from './components/hero-section/HeroBody';
import Footer from './components/footer/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Blog from './components/blog/Blog';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import SideBar from './components/hero-section/SideBar';
import RecomendedHome from './components/recommended/RecomendedHome';

const App = () => {
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const handleSideBar = () => {
    setToggleSideBar(!toggleSideBar);
  };

  return (
    <div>
      <Router>
        <Header handleSideBar={handleSideBar} />
        <SideBar toggleSideBar={toggleSideBar} handleSideBar={handleSideBar} />
        <Routes>
          <Route path="/" exact element={<HeroBody />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/blog/:id" exact element={<Blog />} />
          <Route path="/recommended-blogs" exact element={<RecomendedHome />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
