import React from "react";
// import postImg14 from '../../assets/blog/post14.webp';
// import postImg15 from '../../assets/blog/post15.webp';
// import postImg16 from '../../assets/blog/post16.webp';
// import postImg17 from '../../assets/blog/post17.webp';
// import postImg18 from '../../assets/blog/post18.webp';
// import postImg19 from '../../assets/blog/post19.webp';
// import postImg20 from '../../assets/blog/post20.webp';
// import postImg22 from '../../assets/blog/post22.webp';
import bannerImg2 from "../../assets/banner/banner2.webp";
// import profileImg1 from '../../assets/blog/profile1.webp';
// import profileImg2 from '../../assets/blog/profile2.webp';
// import profileImg3 from '../../assets/blog/profile3.webp';
// import profileImg4 from '../../assets/blog/profile4.webp';
// import profileImg5 from '../../assets/blog/profile5.webp';
import { Link } from "react-router-dom";

const RecentArticles = ({ data }) => {
  console.log(data);
  return (
    <div>
      <section className="post-wrap-layout6 space-top-50 bg-color-light-1 transition-default">
        <div className="container">
          <div className="section-heading heading-style-1">
            <h2 className="title">Recent Articles</h2>
          </div>
          <div className="row sticky-coloum-wrap">
            <div className="col-lg-8 col-12 sticky-coloum-item">
              <div className="row g-3 pe-lg-4">
                {data.map((item) =>
                  item.recentBlog && item.recentBlog.bannerImageUrl !== null ? (
                    <div className="col-12" key={item?._id}>
                      <div className="post-box-layout9 box-border-dark-1 radius-default padding-20 figure-scale-animation bg-color-scandal box-shadow-large shadow-style-2 transition-default">
                        <div className="figure-holder radius-default">
                          <Link
                            to={`/blog/${item.recentBlog._id}`}
                            className="link-wrap"
                          >
                            <img
                              className="object-fit-cover w-100"
                              height="280"
                              src={item?.recentBlog?.bannerImageUrl}
                              alt="Post"
                            />
                          </Link>
                        </div>
                        <div className="content-holder">
                          <div>
                            <div className="entry-category style-2 color-dark-1-fixed">
                              <ul>
                                <li>
                                  <Link to={`/blog/${item?.recentBlog?._id}`}>
                                    {item?.categoryName}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <h3 className="entry-title color-dark-1-fixed underline-animation">
                              <Link
                                to={`/blog/${item?.recentBlog?._id}`}
                                className="link-wrap"
                              >
                                {item?.recentBlog?.title}
                              </Link>
                            </h3>
                            {/* <p className="entry-description color-dark-1-fixed">
                            Nam eget lorem mattis, consequat felis quis, luctus
                            augue. Aenean ac iaculis enim.
                          </p> */}
                            {/* <ul className="entry-meta color-dark-1-fixed">
                            <li className="post-author">
                              <a href="author.html">
                                <img src={profileImg1} alt="Author" />
                                Esther Howard
                              </a>
                            </li>
                            <li>
                              <i className="bi bi-clock" />3 min read
                            </li>
                            <li>
                              <i className="bi bi-eye" />
                              4k
                            </li>
                          </ul> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
                {/* <div className="col-12">
                  <div className="post-box-layout9 box-border-dark-1 radius-default padding-20 figure-scale-animation bg-color-mimosa box-shadow-large shadow-style-2 transition-default">
                    <div className="figure-holder radius-default">
                      <a
                        href="post-format-default.html"
                        className="link-wrap img-height-100"
                      >
                        <img
                          width={500}
                          height={500}
                          src={postImg18}
                          alt="Post"
                        />
                      </a>
                    </div>
                    <div className="content-holder">
                      <div>
                        <div className="entry-category style-2 color-dark-1-fixed">
                          <ul>
                            <li>
                              <a href="archive-layout1.html">EDUCATION</a>
                            </li>
                          </ul>
                        </div>
                        <h3 className="entry-title color-dark-1-fixed underline-animation">
                          <a
                            href="post-format-default.html"
                            className="link-wrap"
                          >
                            Millions Of Manuscripts Are Written Aenean Ac
                            Iaculis
                          </a>
                        </h3>
                        <p className="entry-description color-dark-1-fixed">
                          Nam eget lorem mattis, consequat felis quis, luctus
                          augue. Aenean ac iaculis enim.
                        </p>
                        <ul className="entry-meta color-dark-1-fixed">
                          <li className="post-author">
                            <a href="author.html">
                              <img
                                src={profileImg2}
                                alt="Author"
                              />
                              John Philipe
                            </a>
                          </li>
                          <li>
                            <i className="bi bi-clock" />4 min read
                          </li>
                          <li>
                            <i className="bi bi-eye" />
                            2k
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="post-box-layout9 box-border-dark-1 radius-default padding-20 figure-scale-animation bg-color-selago box-shadow-large shadow-style-2 transition-default">
                    <div className="figure-holder radius-default">
                      <a
                        href="post-format-default.html"
                        className="link-wrap img-height-100"
                      >
                        <img
                          width={500}
                          height={500}
                          src={postImg19}
                          alt="Post"
                        />
                      </a>
                    </div>
                    <div className="content-holder">
                      <div>
                        <div className="entry-category style-2 color-dark-1-fixed">
                          <ul>
                            <li>
                              <a href="archive-layout1.html">HISTORY</a>
                            </li>
                          </ul>
                        </div>
                        <h3 className="entry-title color-dark-1-fixed underline-animation">
                          <a
                            href="post-format-default.html"
                            className="link-wrap"
                          >
                            Underwater Exercise Is Used Strengthen Weak Muscles
                          </a>
                        </h3>
                        <p className="entry-description color-dark-1-fixed">
                          Nam eget lorem mattis, consequat felis quis, luctus
                          augue. Aenean ac iaculis enim.
                        </p>
                        <ul className="entry-meta color-dark-1-fixed">
                          <li className="post-author">
                            <a href="author.html">
                              <img
                                src={profileImg3}
                                alt="Author"
                              />
                              Alisa Michaels
                            </a>
                          </li>
                          <li>
                            <i className="bi bi-clock" />6 min read
                          </li>
                          <li>
                            <i className="bi bi-eye" />
                            7k
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="post-box-layout9 box-border-dark-1 radius-default padding-20 figure-scale-animation bg-color-old-lace box-shadow-large shadow-style-2 transition-default">
                    <div className="figure-holder radius-default">
                      <a
                        href="post-format-default.html"
                        className="link-wrap img-height-100"
                      >
                        <img
                          width={500}
                          height={500}
                          src={postImg20}
                          alt="Post"
                        />
                      </a>
                    </div>
                    <div className="content-holder">
                      <div>
                        <div className="entry-category style-2 color-dark-1-fixed">
                          <ul>
                            <li>
                              <a href="archive-layout1.html">FOOD</a>
                            </li>
                          </ul>
                        </div>
                        <h3 className="entry-title color-dark-1-fixed underline-animation">
                          <a
                            href="post-format-default.html"
                            className="link-wrap"
                          >
                            Smarter Food Choices 101 Tips For Busy Women
                          </a>
                        </h3>
                        <p className="entry-description color-dark-1-fixed">
                          Nam eget lorem mattis, consequat felis quis, luctus
                          augue. Aenean ac iaculis enim.
                        </p>
                        <ul className="entry-meta color-dark-1-fixed">
                          <li className="post-author">
                            <a href="author.html">
                              <img
                                src={profileImg4}
                                alt="Author"
                              />
                              Ashley Graham
                            </a>
                          </li>
                          <li>
                            <i className="bi bi-clock" />9 min read
                          </li>
                          <li>
                            <i className="bi bi-eye" />
                            1k
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="post-box-layout9 box-border-dark-1 radius-default padding-20 figure-scale-animation bg-color-scandal box-shadow-large shadow-style-2 transition-default">
                    <div className="figure-holder radius-default">
                      <a
                        href="post-format-default.html"
                        className="link-wrap img-height-100"
                      >
                        <img
                          width={500}
                          height={500}
                          src={postImg15}
                          alt="Post"
                        />
                      </a>
                    </div>
                    <div className="content-holder">
                      <div>
                        <div className="entry-category style-2 color-dark-1-fixed">
                          <ul>
                            <li>
                              <a href="archive-layout1.html">FASHION</a>
                            </li>
                          </ul>
                        </div>
                        <h3 className="entry-title color-dark-1-fixed underline-animation">
                          <a
                            href="post-format-default.html"
                            className="link-wrap"
                          >
                            Virtual Reality (VR), The Use Computer Modeling
                          </a>
                        </h3>
                        <p className="entry-description color-dark-1-fixed">
                          Nam eget lorem mattis, consequat felis quis, luctus
                          augue. Aenean ac iaculis enim.
                        </p>
                        <ul className="entry-meta color-dark-1-fixed">
                          <li className="post-author">
                            <a href="author.html">
                              <img
                                src={profileImg5}
                                alt="Author"
                              />
                              Sergio Pliego
                            </a>
                          </li>
                          <li>
                            <i className="bi bi-clock" />1 min read
                          </li>
                          <li>
                            <i className="bi bi-eye" />
                            3k
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="post-box-layout9 box-border-dark-1 radius-default padding-20 figure-scale-animation bg-color-mimosa box-shadow-large shadow-style-2 transition-default">
                    <div className="figure-holder radius-default">
                      <a
                        href="post-format-default.html"
                        className="link-wrap img-height-100"
                      >
                        <img
                          width={500}
                          height={500}
                          src={postImg22}
                          alt="Post"
                        />
                      </a>
                    </div>
                    <div className="content-holder">
                      <div>
                        <div className="entry-category style-2 color-dark-1-fixed">
                          <ul>
                            <li>
                              <a href="archive-layout1.html">WORLD</a>
                            </li>
                          </ul>
                        </div>
                        <h3 className="entry-title color-dark-1-fixed underline-animation">
                          <a
                            href="post-format-default.html"
                            className="link-wrap"
                          >
                            Reality Hosting, The Use Computer Modeling
                          </a>
                        </h3>
                        <p className="entry-description color-dark-1-fixed">
                          Nam eget lorem mattis, consequat felis quis, luctus
                          augue. Aenean ac iaculis enim.
                        </p>
                        <ul className="entry-meta color-dark-1-fixed">
                          <li className="post-author">
                            <a href="author.html">
                              <img
                                src={profileImg1}
                                alt="Author"
                              />
                              Kristin Watson
                            </a>
                          </li>
                          <li>
                            <i className="bi bi-clock" />
                            16 min read
                          </li>
                          <li>
                            <i className="bi bi-eye" />
                            13k
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-12 sticky-coloum-item">
              <div className="sidebar-global sidebar-layout1">
                <div className="sidebar-widget">
                  <div className="widget-banner banner-layout1">
                    <div className="radius-default box-border-dark-1 img-height-100">
                      <div className="figure-holder radius-medium">
                        <img
                          width={700}
                          height={294}
                          src={bannerImg2}
                          alt="Banner"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sidebar-widget">
                  <div className="section-heading heading-style-2">
                    <h3 className="title">Explore Topics</h3>
                  </div>
                  <div className="widget-category category-layout1 bg-color-tidal radius-default box-border-dark-1">
                    <ul className="category-list">
                      <li>
                        <a href="archive-layout1.html">Culture</a>(2)
                      </li>
                      <li>
                        <a href="archive-layout1.html">Travel</a>(1)
                      </li>
                      <li>
                        <a href="archive-layout1.html">Business</a>(5)
                      </li>
                      <li>
                        <a href="archive-layout1.html">Trending</a>(4)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sidebar-widget">
                  <div className="widget-follow follow-layout1 radius-default padding-40 box-border-dark-1">
                    <h3 className="title h3-medium">Follow Us</h3>
                    <p className="description">Follow us on Social Network</p>
                    <div className="axil-social social-layout-1 size-small gap-12 justify-content-center">
                      <ul>
                        <li className="facebook">
                          <a href="#" aria-label="Learn more from Facebook">
                            <i className="bi bi-facebook" />
                          </a>
                        </li>
                        <li className="instagram">
                          <a href="#" aria-label="Learn more from Instagram">
                            <i className="bi bi-instagram" />
                          </a>
                        </li>
                        <li className="mail-fast">
                          <a aria-label="Learn more from Mail fast" href="#">
                            <i className="bi bi-envelope" />
                          </a>
                        </li>
                        <li className="pinterest">
                          <a href="#" aria-label="Learn more from Pinterest">
                            <i className="bi bi-pinterest" />
                          </a>
                        </li>
                        <li className="youtube">
                          <a href="#" aria-label="Learn more from Youtube">
                            <i className="bi bi-youtube" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="sidebar-widget">
                  <div className="section-heading heading-style-2">
                    <h3 className="title">Short Stories</h3>
                  </div>
                  <div className="widget-post post-layout1">
                    <div className="post-box">
                      <div className="figure-holder radius-default">
                        <a
                          href="post-format-default.html"
                          className="link-wrap figure-overlay"
                        >
                          <img
                            className="object-fit-cover w-100"
                            height={270}
                            src={data[4]?.recentBlog.bannerImageUrl}
                            alt="Post"
                          />
                        </a>
                      </div>
                      <div className="content-holder">
                        <h3 className="entry-title color-light-1-fixed h3-small underline-animation">
                          <Link
                            to={`/blog/${data[4]?.recentBlog?._id}`}
                            className="link-wrap"
                          >
                            {data[4]?.recentBlog?.title}
                          </Link>
                        </h3>
                        <ul className="entry-meta color-light-1-fixed">
                          <li>
                            <i className="bi bi-clock" />2 min read
                          </li>
                        </ul>
                      </div>
                    </div>
                    {data?.map((item) =>
                      item.recentBlog &&
                      item.recentBlog.bannerImageUrl !== null ? (
                        <div className="post-box" key={item?._id}>
                          <div className="figure-holder radius-default">
                            <Link
                              to={`/blog/${item.recentBlog._id}`}
                              className="link-wrap figure-overlay"
                            >
                              <img
                                className="object-fit-cover"
                                width={100}
                                height={100}
                                src={item?.recentBlog?.bannerImageUrl}
                                alt="Post"
                              />
                            </Link>
                          </div>
                          <div className="content-holder">
                            <h3 className="entry-title color-dark-1 underline-animation h3-extra-small">
                              <Link
                                to={`/blog/${item.recentBlog._id}`}
                                className="link-wrap"
                              >
                                {item?.recentBlog?.title}
                              </Link>
                            </h3>
                            <ul className="entry-meta color-dark-1">
                              <li>
                                <i className="bi bi-clock" />2 min read
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                    {/* <div className="post-box">
                      <div className="figure-holder radius-default">
                        <a
                          href="post-format-default.html"
                          className="link-wrap figure-overlay img-height-100"
                        >
                          <img
                            width={140}
                            height={140}
                            src={postImg15}
                            alt="Post"
                          />
                        </a>
                      </div>
                      <div className="content-holder">
                        <h3 className="entry-title color-dark-1 underline-animation h3-extra-small">
                          <a
                            href="post-format-default.html"
                            className="link-wrap"
                          >
                            Virtual Reality (VR), The Use Computer Modeling
                          </a>
                        </h3>
                        <ul className="entry-meta color-dark-1">
                          <li>
                            <i className="bi bi-clock" />8 min read
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="post-box">
                      <div className="figure-holder radius-default">
                        <a
                          href="post-format-default.html"
                          className="link-wrap figure-overlay img-height-100"
                        >
                          <img
                            width={140}
                            height={140}
                            src={postImg16}
                            alt="Post"
                          />
                        </a>
                      </div>
                      <div className="content-holder">
                        <h3 className="entry-title color-dark-1 underline-animation h3-extra-small">
                          <a
                            href="post-format-default.html"
                            className="link-wrap"
                          >
                            Reality Hosting, The Use Computer Modeling
                          </a>
                        </h3>
                        <ul className="entry-meta color-dark-1">
                          <li>
                            <i className="bi bi-clock" />5 min read
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="sidebar-widget">
                  <div className="section-heading heading-style-2">
                    <h3 className="title">Recommended topics</h3>
                  </div>
                  <div className="widget-tagcloud tagcloud-layout1">
                    <a
                      href="archive-layout1.html"
                      className="tag-cloud-link box-shadow-small shadow-style-2 box-border-dark-1"
                    >
                      <span className="icon-holder">
                        <i className="bi bi-lungs" />{" "}
                      </span>
                      Healthcare
                    </a>
                    <a
                      href="archive-layout1.html"
                      className="tag-cloud-link box-shadow-small shadow-style-2 box-border-dark-1"
                    >
                      <span className="icon-holder">
                        <i className="bi bi-person-standing-dress" />{" "}
                      </span>
                      Fashion
                    </a>
                    <a
                      href="archive-layout1.html"
                      className="tag-cloud-link box-shadow-small shadow-style-2 box-border-dark-1"
                    >
                      <span className="icon-holder">
                        <i className="bi bi-globe" />{" "}
                      </span>
                      History
                    </a>
                    <a
                      href="archive-layout1.html"
                      className="tag-cloud-link box-shadow-small shadow-style-2 box-border-dark-1"
                    >
                      <span className="icon-holder">
                        <i className="bi bi-backpack" />{" "}
                      </span>
                      Education
                    </a>
                    <a
                      href="archive-layout1.html"
                      className="tag-cloud-link box-shadow-small shadow-style-2 box-border-dark-1"
                    >
                      <span className="icon-holder">
                        <i className="bi bi-globe-central-south-asia" />{" "}
                      </span>
                      World
                    </a>
                    <a
                      href="archive-layout1.html"
                      className="tag-cloud-link box-shadow-small shadow-style-2 box-border-dark-1"
                    >
                      <span className="icon-holder">
                        <i className="bi bi-globe" />{" "}
                      </span>
                      History
                    </a>
                    <a
                      href="archive-layout1.html"
                      className="tag-cloud-link box-shadow-small shadow-style-2 box-border-dark-1"
                    >
                      <span className="icon-holder">
                        <i className="bi bi-backpack" />{" "}
                      </span>
                      Education
                    </a>
                    <a
                      href="archive-layout1.html"
                      className="tag-cloud-link box-shadow-small shadow-style-2 box-border-dark-1"
                    >
                      <span className="icon-holder">
                        <i className="bi bi-globe" />{" "}
                      </span>
                      History
                    </a>
                    <a
                      href="archive-layout1.html"
                      className="tag-cloud-link box-shadow-small shadow-style-2 box-border-dark-1"
                    >
                      <span className="icon-holder">
                        <i className="bi bi-person-standing-dress" />{" "}
                      </span>
                      Fashion
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RecentArticles;
