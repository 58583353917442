import React from "react";
import { Link } from "react-router-dom";

const CardContainer = ({ data }) => {
  return (
    <div>
      <section className="post-wrap-layout8 space-top-50 bg-color-light-1 transition-default">
        <div className="container">
          <div className="row g-3">
            {data.map((item) =>
              item.recentBlog && item.recentBlog.bannerImageUrl !== null ? (
                <div key={item._id} className="col-lg-4">
                  <div className="h-100 post-box-layout12 box-border-dark-1 radius-default padding-20 bg-color-scandal box-shadow-large shadow-style-2 transition-default">
                    <div className="single-item">
                      <div className="figure-holder radius-default">
                        <Link
                          to={`post/${item.recentBlog._id}`}
                          className="link-wrap"
                        >
                          <img
                            className="object-fit-cover w-100"
                            height={240}
                            src={item.recentBlog.bannerImageUrl}
                            alt={item.recentBlog.bannerAltDesc}
                          />
                        </Link>
                      </div>
                      <div className="content-holder">
                        <div className="entry-category style-2 color-dark-1-fixed">
                          <ul>
                            <li>
                              <a href={`category/${item.categoryName}`}>
                                {item.categoryName}
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h3 className="entry-title h3-small color-dark-1-fixed underline-animation">
                          <Link
                            to={`post/${item.recentBlog._id}`}
                            className="link-wrap"
                          >
                            {item.recentBlog.title}
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default CardContainer;
