import React from "react";
import postImg12 from "../../assets/blog/post12.webp";
import { Link } from "react-router-dom";
const LatestStories = ({ data }) => {
  return (
    <div>
      <section className="post-wrap-layout4 space-top-50 bg-color-light-1 transition-default">
        <div className="container">
          <div className="section-heading heading-style-1">
            <h2 className="title">Latest Stories</h2>
          </div>
          <div className="row g-3">
            <div className="col-lg-8">
              <div className="row g-3">
                <div className="col-lg-12">
                  <div className="post-box-layout5 box-border-dark-1 radius-default">
                    <div className="figure-holder radius-medium">
                      <Link
                        to={`/blog/${data[3]?.recentBlog?._id}`}
                        className="link-wrap figure-overlay img-height-100"
                      >
                        <img
                          className="object-fit-cover"
                          width="810"
                          height="558"
                          src={data[3]?.recentBlog?.bannerImageUrl}
                          alt="Post"
                        />
                      </Link>
                    </div>
                    <div className="content-holder">
                      <div className="entry-category style-2 color-dark-1-fixed">
                        <ul>
                          <li>
                            <Link to={`/blog/${data[3]?.recentBlog?._id}`}>
                              {data[3]?.categoryName}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h3 className="entry-title h3-large color-light-1-fixed underline-animation">
                        <Link
                          to={`/blog/${data[3]?.recentBlog?._id}`}
                          className="link-wrap"
                        >
                          {data[3]?.recentBlog?.title}
                        </Link>
                      </h3>
                      {/* <ul className="entry-meta color-light-1-fixed">
                        <li className="post-author">
                          <a href="author.html">
                            <img src={postImg2} alt="Author" /> John Philipe
                          </a>
                        </li>
                        <li>
                          <i className="bi bi-clock"></i>7 min read
                        </li>
                        <li>
                          <i className="bi bi-eye"></i>9k
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="post-box-layout5 box-border-dark-1 radius-default padding-20 bg-color-selago box-shadow-large shadow-style-2 transition-default">
                    <div className="figure-holder radius-default">
                      <Link
                        to={`/blog/${data[1]?.recentBlog?._id}`}
                        className="link-wrap figure-overlay"
                      >
                        <img
                          className="object-fit-cover w-100"
                          height="270"
                          src={data[1]?.recentBlog?.bannerImageUrl}
                          alt="Post"
                        />
                      </Link>
                    </div>
                    <div className="content-holder">
                      <div className="entry-category style-3 color-light-1-fixed">
                        <ul>
                          <li>
                            <Link to={`/blog/${data[1]?.recentBlog?._id}`}>
                              {data[1]?.categoryName}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h3 className="entry-title h3-small color-light-1-fixed underline-animation mb-0">
                        <Link
                          to={`/blog/${data[1]?.recentBlog?._id}`}
                          className="link-wrap"
                        >
                          {data[1]?.recentBlog?.title}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="post-box-layout5 box-border-dark-1 radius-default padding-20 bg-color-selago box-shadow-large shadow-style-2 transition-default">
                    <div className="figure-holder radius-default">
                      <Link
                        to={`/blog/${data[3]?.recentBlog?._id}`}
                        className="link-wrap figure-overlay"
                      >
                        <img
                          className="object-fit-cover w-100"
                          height="270"
                          src={data[3]?.recentBlog?.bannerImageUrl}
                          alt="Post"
                        />
                      </Link>
                    </div>
                    <div className="content-holder">
                      <div className="entry-category style-3 color-light-1-fixed">
                        <ul>
                          <li>
                            <Link to={`/blog/${data[7]?.recentBlog?._id}`}>
                              {data[4]?.categoryName}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h3 className="entry-title h3-small color-light-1-fixed underline-animation mb-0">
                        <Link
                          to={`/blog/${data[4]?.recentBlog?._id}`}
                          className="link-wrap"
                        >
                          {data[4]?.recentBlog?.title}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="post-box-layout7 box-border-dark-1 radius-default padding-20 bg-color-scandal">
                <div className="figure-holder radius-default">
                  <Link
                    to={`/blog/${data[4]?.recentBlog?._id}`}
                    className="link-wrap"
                  >
                    <img
                      className="object-fit-cover w-100"
                      height="270"
                      src={data[4]?.recentBlog?.bannerImageUrl}
                      alt="Post"
                    />
                  </Link>
                </div>
                <div className="content-holder">
                  <h3 className="entry-title h3-small color-dark-1-fixed underline-animation">
                    <Link
                      to={`/blog/${data[7]?.recentBlog?._id}`}
                      className="link-wrap"
                    >
                      {data[7]?.recentBlog?.title}
                    </Link>
                  </h3>
                  <ul className="entry-meta color-dark-1-fixed">
                    <li className="post-author">
                      <Link to={`/blog/${data[7]?.recentBlog?._id}`}>
                        Alisa Michaels
                      </Link>
                    </li>
                    <li>
                      <i className="bi bi-clock"></i>9 min read
                    </li>
                    <li>
                      <i className="bi bi-eye"></i>4k
                    </li>
                  </ul>
                </div>
                {data?.map((item) =>
                  item.recentBlog?._id ? (
                    <div className="content-holder" key={item?._id}>
                      <h3 className="entry-title h3-small color-dark-1-fixed underline-animation">
                        <Link
                          to={`/blog/${item.recentBlog._id}`}
                          className="link-wrap"
                        >
                          {item?.recentBlog?.title}
                        </Link>
                      </h3>
                      <ul className="entry-meta color-dark-1-fixed">
                        <li className="post-author">
                          <Link to={`/blog/${item?.recentBlog?._id}`}>
                            Alisa Michaels
                          </Link>
                        </li>
                        <li>
                          <i className="bi bi-clock"></i>9 min read
                        </li>
                        <li>
                          <i className="bi bi-eye"></i>4k
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )
                )}
                {/* <div className="content-holder">
                  <h3 className="entry-title h3-small color-dark-1-fixed underline-animation">
                    <a href="post-format-default.html" className="link-wrap">
                      The Classic Foundation Is Vanishing
                    </a>
                  </h3>
                  <ul className="entry-meta color-dark-1-fixed">
                    <li className="post-author">
                      <a href="author.html">Sergio Pliego</a>
                    </li>
                    <li>
                      <i className="bi bi-clock"></i>1 min read
                    </li>
                    <li>
                      <i className="bi bi-eye"></i>3k
                    </li>
                  </ul>
                </div>
                <div className="content-holder">
                  <h3 className="entry-title h3-small color-dark-1-fixed underline-animation">
                    <a href="post-format-default.html" className="link-wrap">
                      Smarter Food Choices 101 Tips For Busy Women
                    </a>
                  </h3>
                  <ul className="entry-meta color-dark-1-fixed">
                    <li className="post-author">
                      <a href="author.html">Kristin Watson</a>
                    </li>
                    <li>
                      <i className="bi bi-clock"></i>5 min read
                    </li>
                    <li>
                      <i className="bi bi-eye"></i>12k
                    </li>
                  </ul>
                </div>
                <div className="content-holder">
                  <h3 className="entry-title h3-small color-dark-1-fixed underline-animation">
                    <a href="post-format-default.html" className="link-wrap">
                      Documents And Witness Reveal That Also
                    </a>
                  </h3>
                  <ul className="entry-meta color-dark-1-fixed">
                    <li className="post-author">
                      <a href="author.html">Jenny Wilson</a>
                    </li>
                    <li>
                      <i className="bi bi-clock"></i>6 min read
                    </li>
                    <li>
                      <i className="bi bi-eye"></i>8k
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LatestStories;
