import React, { useEffect, useState } from 'react';
import HeroArea from './HeroArea';
import HeroBanner from './HeroBanner';
import PostAreaCarousel from './PostAreaCarousel';
import TopStories from './TopStories';
import CategoryArea from './CategoryArea';
import LatestStories from './LatestStories';
// import TopVideos from './TopVideos';
import RecentArticles from './RecentArticles';
import NewRecentArticles from './NewRecentArticles';
import TopColumnist from './TopColumnist';
import CardContainer from './CardContainer';
import NewsLetterEmail from './NewsLetterEmail';
import { constant } from '../../utils/constant';

const HeroBody = () => {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await fetch(constant.CONST_SARCASM_BLOG_RECENT);
      const json = await response.json();
      if (json.success) {
        setData(json.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <HeroArea />
      <HeroBanner data={data} />
      <PostAreaCarousel data={data} />
      <TopStories data={data} />
      <CategoryArea data={data} />
      <LatestStories data={data} />
      {/* <TopVideos /> */}
      <RecentArticles data={data} />
      <NewRecentArticles data={data} />
      <TopColumnist />
      <CardContainer data={data} />
      <NewsLetterEmail />
    </div>
  );
};

export default HeroBody;
