import React from 'react';
import BlogPostCard from './BlogPostCard';
import VideoPostCard from './VideoPostCard';
import PostCarouselSlider from './PostCarouselSlider';
import PostCard from './PostCard';
import authorImg from '../../assets/blog/profile4.webp';
import videoSrc from '../../assets/post-format-video-1.mp4';
import BlogPostPreview from './BlogPostPreview';

const RecomendedHome = () => {
  const post = {
    title: 'Millions Of Manuscripts Are Written',
    date: 'August 29, 2023',
    readTime: '9 min read',
    views: '9k',
    videoSrc: videoSrc,
    videoId: 'video-player-1',
    description:
      'On August 15th, an alarming email popped up in the inbox of Diana Pearl...',
    authorName: 'Kristin Watson',
    authorImg: authorImg,
    authorLink: 'author.html',
    postLink: 'post-format-default.html',
    socialLinks: {
      facebook: 'https://facebook.com/',
      mailFast: 'https://mail-fast.com/',
      pinterest: 'https://pinterest.com/',
    },
  };
  return (
    <div>
      <section className="archive-wrap-layout-1 space-top-60 bg-color-light-1 transition-default">
        <div className="container">
          <div className="row g-3 justify-content-center">
            <div className="col-xl-8 col-lg-9 col-sm-10 col-12">
              <BlogPostCard />
              <VideoPostCard />
              <PostCarouselSlider />
              <PostCard post={post} />
              <BlogPostPreview />
            </div>
          </div>
          {/* <div className="template-pagination pagination-center">
            <ul>
              <li className="disabled">Prev</li>
              <li className="selected">
                <a href="#">1</a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li className="disabled">...</li>
              <li>
                <a href="#">25</a>
              </li>
              <li>
                <a href="#">Next</a>
              </li>
            </ul>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default RecomendedHome;
