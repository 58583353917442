import React from "react";
import Slider from "react-slick";
import teamImg1 from "../../assets/team/team1.webp";
import teamImg2 from "../../assets/team/team2.webp";
import teamImg3 from "../../assets/team/team3.webp";
import teamImg7 from "../../assets/team/team7.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick_next slick-arrow" onClick={onClick}>
      <i class="bi bi-arrow-right"></i>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick_prev slick-arrow" onClick={onClick}>
      <i class="bi bi-arrow-left"></i>
    </div>
  );
}

const TopColumnist = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section className="team-wrap-layout1 space-top-50 bg-color-light-1 transition-default topColumnist">
        <div className="container">
          <div className="border-bottom-dark">
            <div className="section-heading heading-style-1">
              <h2 className="title">Top Columnist</h2>
            </div>
            <Slider {...settings}>
              <div className="single-slide">
                <div className="team-box-layout1">
                  <div className="figure-holder box-border-dark-1">
                    <a href="author.html" className="link-wrap img-height-100">
                      <img width={140} height={140} src={teamImg1} alt="Team" />
                    </a>
                  </div>
                  <div className="content-holder">
                    <h3 className="entry-title h3-extra-small">
                      <a href="author.html" className="link-wrap">
                        Robert Jhon
                      </a>
                    </h3>
                    <div className="skill-box">
                      <div className="skill-for">Specialise in</div>
                      <ul className="skill-on">
                        <li>Entertainment,</li>
                        <li>Culture</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="single-slide">
                <div className="team-box-layout1">
                  <div className="figure-holder box-border-dark-1">
                    <a href="author.html" className="link-wrap img-height-100">
                      <img width={140} height={140} src={teamImg2} alt="Team" />
                    </a>
                  </div>
                  <div className="content-holder">
                    <h3 className="entry-title h3-extra-small">
                      <a href="author.html" className="link-wrap">
                        Susana Mig
                      </a>
                    </h3>
                    <div className="skill-box">
                      <div className="skill-for">Specialise in</div>
                      <ul className="skill-on">
                        <li>Entertainment,</li>
                        <li>Culture</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="single-slide">
                <div className="team-box-layout1">
                  <div className="figure-holder box-border-dark-1">
                    <a href="author.html" className="link-wrap img-height-100">
                      <img width={140} height={140} src={teamImg3} alt="Team" />
                    </a>
                  </div>
                  <div className="content-holder">
                    <h3 className="entry-title h3-extra-small">
                      <a href="author.html" className="link-wrap">
                        Amy Amber
                      </a>
                    </h3>
                    <div className="skill-box">
                      <div className="skill-for">Specialise in</div>
                      <ul className="skill-on">
                        <li>Entertainment,</li>
                        <li>Culture</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="single-slide">
                <div className="team-box-layout1">
                  <div className="figure-holder box-border-dark-1">
                    <a href="author.html" className="link-wrap img-height-100">
                      <img width={140} height={140} src={teamImg7} alt="Team" />
                    </a>
                  </div>
                  <div className="content-holder">
                    <h3 className="entry-title h3-extra-small">
                      <a href="author.html" className="link-wrap">
                        Riya Pall
                      </a>
                    </h3>
                    <div className="skill-box">
                      <div className="skill-for">Specialise in</div>
                      <ul className="skill-on">
                        <li>Entertainment,</li>
                        <li>Culture</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TopColumnist;
