import React from 'react';

const BlogBody = ({ data }) => {
  const { bannerImageUrl } = data || {};

  // console.log(bannerImageUrl);
  const updateImgSrc = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
console.log(data);
    const imgSrcMap = {
      banner: bannerImageUrl,
      // author: blogImages && blogImages?.bannerImageUrl,
      // blog: blogImages && blogImages?.bannerImageUrl,
      // post3: blogImages && blogImages?.bannerImageUrl,
    };

    for (const [id, src] of Object.entries(imgSrcMap)) {
      const img = doc.querySelector(`img#${id}`);
      if (img && src) {
        img.src = src;
      }
    }
    return doc.body.innerHTML;
  };

  const htmlContent = data?.body || '';

  return (
    <div dangerouslySetInnerHTML={{ __html: updateImgSrc(htmlContent) }}></div>
  );
};

export default BlogBody;
