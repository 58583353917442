import React from "react";
// import postImg109 from '../../assets/blog/post109.webp';
// import postImg29 from '../../assets/blog/post29.webp';
// import postImg30 from '../../assets/blog/post30.webp';
// import postImg31 from '../../assets/blog/post31.webp';
// import postImg32 from '../../assets/blog/post32.webp';
import { Link } from "react-router-dom";

const NewRecentArticles = ({ data }) => {
  return (
    <div>
      <section className="post-wrap-layout7 space-top-40 bg-color-light-1 transition-default">
        <div className="container">
          <div className="section-heading heading-style-1">
            <h2 className="title">Recent Articles</h2>
            <a href="archive-layout1.html" className="link-wrap">
              Go to Stories
              <span className="icon-holder">
                <i className="bi bi-arrow-right-short" />
              </span>
            </a>
          </div>
          <div className="row g-3">
            <div className="col-lg-6">
              <div className="post-box-layout10 box-border-dark-1 radius-default both-side-equal">
                <div className="figure-holder radius-medium">
                  <a
                    href="post-format-default.html"
                    className="link-wrap figure-overlay img-height-100"
                  >
                    <img
                      className="object-fit-cover"
                      width={630}
                      height={500}
                      src={data[4]?.recentBlog?.bannerImageUrl}
                      alt="Post"
                    />
                  </a>
                </div>
                <div className="content-holder">
                  <div className="entry-category style-2 color-dark-1-fixed">
                    <ul>
                      <li>
                        <a href="archive-layout1.html">
                          {data[4]?.categoryName}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h3 className="entry-title h3-large color-light-1-fixed underline-animation">
                    <Link
                      to={`/blog/${data[4]?.recentBlog?._id}`}
                      className="link-wrap"
                    >
                      {data[4]?.recentBlog?.title}
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row g-3">
                {data?.slice(4, 8)?.map((item) => (
                  <div className="col-md-6 col-12" key={item?._id}>
                    <div className="h-100 post-box-layout11 box-border-dark-1 radius-default padding-20 bg-color-scandal box-shadow-small shadow-style-2 transition-default">
                      <div className="figure-holder radius-default">
                        <Link
                          to={`/blog/${item.recentBlog._id}`}
                          className="link-wrap"
                        >
                          <img
                            className="object-fit-cover w-100"
                            height={180}
                            src={item?.recentBlog?.bannerImageUrl}
                            alt="Post"
                          />
                        </Link>
                      </div>
                      <div className="content-holder">
                        <div className="entry-category style-2 color-dark-1-fixed">
                          <ul>
                            <li>
                              <Link to={`/blog/${item.recentBlog._id}`}>
                                {item?.categoryName}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <h3 className="entry-title h3-small color-dark-1-fixed underline-animation">
                          <Link
                            to={`/blog/${item.recentBlog._id}`}
                            className="link-wrap"
                          >
                            {item?.recentBlog?.title}
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="col-md-6 col-12">
                  <div className="post-box-layout11 box-border-dark-1 radius-default padding-20 bg-color-mimosa box-shadow-small shadow-style-2 transition-default">
                    <div className="figure-holder radius-default">
                      <a
                        href="post-format-default.html"
                        className="link-wrap img-height-100"
                      >
                        <img
                          width={480}
                          height={344}
                          src={postImg30}
                          alt="Post"
                        />
                      </a>
                    </div>
                    <div className="content-holder">
                      <div className="entry-category style-2 color-dark-1-fixed">
                        <ul>
                          <li>
                            <a href="archive-layout1.html">TECH</a>
                          </li>
                        </ul>
                      </div>
                      <h3 className="entry-title h3-small color-dark-1-fixed underline-animation">
                        <a
                          href="post-format-default.html"
                          className="link-wrap"
                        >
                          Air Pods Pro With Wireless Charging Case
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="post-box-layout11 box-border-dark-1 radius-default padding-20 bg-color-selago box-shadow-small shadow-style-2 transition-default">
                    <div className="figure-holder radius-default">
                      <a
                        href="post-format-default.html"
                        className="link-wrap img-height-100"
                      >
                        <img
                          width={480}
                          height={344}
                          src={postImg31}
                          alt="Post"
                        />
                      </a>
                    </div>
                    <div className="content-holder">
                      <div className="entry-category style-2 color-dark-1-fixed">
                        <ul>
                          <li>
                            <a href="archive-layout1.html">SPORTS</a>
                          </li>
                        </ul>
                      </div>
                      <h3 className="entry-title h3-small color-dark-1-fixed underline-animation">
                        <a
                          href="post-format-default.html"
                          className="link-wrap"
                        >
                          Virtual Reality (VR), The Use Computer Modeling
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="post-box-layout11 box-border-dark-1 radius-default padding-20 bg-color-old-lace box-shadow-small shadow-style-2 transition-default">
                    <div className="figure-holder radius-default">
                      <a
                        href="post-format-default.html"
                        className="link-wrap img-height-100"
                      >
                        <img
                          width={480}
                          height={344}
                          src={postImg32}
                          alt="Post"
                        />
                      </a>
                    </div>
                    <div className="content-holder">
                      <div className="entry-category style-2 color-dark-1-fixed">
                        <ul>
                          <li>
                            <a href="archive-layout1.html">TRAVEL</a>
                          </li>
                        </ul>
                      </div>
                      <h3 className="entry-title h3-small color-dark-1-fixed underline-animation">
                        <a
                          href="post-format-default.html"
                          className="link-wrap"
                        >
                          Reality Hosting, The Use Computer Modeling
                        </a>
                      </h3>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewRecentArticles;
