import React from 'react';
import mapImg from '../../assets/elements/map.webp';
import NewsLetterEmail from '../hero-section/NewsLetterEmail';

const contact = () => {
  return (
    <div>
      <section className="contact-wrap-layout-1 space-top-50 bg-color-light-1 transition-default">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-heading heading-style-9">
                <h2 className="title">Get In Touch With Us?</h2>
              </div>
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="contact-layout-1 box-border-dark-1 radius-default bg-color-scandal box-shadow-medium shadow-style-2 transition-default">
                    <div className="contact-info-box">
                      <div className="box-icon">
                        <i className="regular-call-out" />
                      </div>
                      <div className="contact-way">(702)123-4567</div>
                      <div className="contact-text">
                        24/7 Support Countrywide
                      </div>
                      <a
                        href="#"
                        className="axil-btn axil-btn-fill shadow-fixed axil-btn-small"
                      >
                        Call Now
                        <div className="icon-holder">
                          <i className="regular-arrow-right" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-layout-1 box-border-dark-1 radius-default bg-color-selago box-shadow-medium shadow-style-2 transition-default">
                    <div className="contact-info-box">
                      <div className="box-icon">
                        <i className="regular-chatting" />
                      </div>
                      <div className="contact-way">Live Chat</div>
                      <div className="contact-text">Have any questions?</div>
                      <a
                        href="#"
                        className="axil-btn axil-btn-ghost shadow-fixed axil-btn-small"
                      >
                        Chat Now
                        <div className="icon-holder">
                          <i className="regular-arrow-right" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="contact-form-wrap box-border-dark-1 radius-default">
                    <h3 className="title">Submit Your Inquiry</h3>
                    <form
                      id="contact-form"
                      method="POST"
                      action="mail.php"
                      className="axil-contact-form"
                    >
                      <input
                        className="form-control"
                        type="text"
                        name="contact-name"
                        id="contact-name"
                        placeholder="Name"
                      />
                      <input
                        className="form-control"
                        type="text"
                        name="contact-phone"
                        id="contact-phone"
                        placeholder="Phone"
                      />
                      <input
                        className="form-control"
                        type="email"
                        name="contact-email"
                        id="contact-email"
                        placeholder="Email"
                      />
                      <textarea
                        className="form-control"
                        name="contact-message"
                        id="contact-message"
                        cols={30}
                        rows={4}
                        placeholder="Message"
                        defaultValue={''}
                      />
                      <div className="select-type">
                        <label className="select-title">
                          What Type Of Blogs Do You Love To Read?
                        </label>
                        <ul className="select-list">
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-1"
                              type="checkbox"
                              defaultValue="value1"
                            />
                            <label htmlFor="styled-checkbox-1">Travel</label>
                          </li>
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-2"
                              type="checkbox"
                              defaultValue="value1"
                            />
                            <label htmlFor="styled-checkbox-2">Health</label>
                          </li>
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-3"
                              type="checkbox"
                              defaultValue="value1"
                            />
                            <label htmlFor="styled-checkbox-3">
                              Music News
                            </label>
                          </li>
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-4"
                              type="checkbox"
                              defaultValue="value1"
                            />
                            <label htmlFor="styled-checkbox-4">Fashion</label>
                          </li>
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-5"
                              type="checkbox"
                              defaultValue="value1"
                            />
                            <label htmlFor="styled-checkbox-5">Education</label>
                          </li>
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-6"
                              type="checkbox"
                              defaultValue="value1"
                            />
                            <label htmlFor="styled-checkbox-6">Other</label>
                          </li>
                        </ul>
                      </div>
                      <button
                        type="submit"
                        className="axil-btn axil-btn-fill axil-btn-large btn-color-alter btn-bold"
                      >
                        Submit Now
                        <i className="bi bi-send-fill" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="map-wrap">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-heading heading-style-9">
                  <h2 className="title">Our Location</h2>
                  <p className="description">
                    Visit our location for your query
                  </p>
                </div>
                <div className="map-box box-border-dark-1 radius-default">
                  <div className="figure-holder radius-medium img-height-100">
                    <img width={810} height={400} src={mapImg} alt="Map" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-heading heading-style-9">
                <h2 className="title">Frequently Asked Questions</h2>
              </div>
              <div className="faq-box-layout1 box-border-dark-1 radius-default">
                <div id="accordion" className="accordion">
                  <div className="card single-item box-border-dark-1 border-top-0 border-start-0 border-end-0">
                    <div className="card-header item-nav">
                      <a
                        data-bs-toggle="collapse"
                        data-bs-parent="#accordion"
                        href="#collapse1"
                      >
                        1. Eight Types of Blogs and Bloggers. What Type is
                        Yours?
                      </a>
                    </div>
                    <div
                      id="collapse1"
                      className="collapse show item-content-wrap"
                      data-bs-parent="#accordion"
                    >
                      <div className="card-body item-content">
                        <p>
                          When an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card single-item box-border-dark-1 border-top-0 border-start-0 border-end-0">
                    <div className="card-header item-nav">
                      <a
                        className="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-parent="#accordion"
                        href="#collapse2"
                      >
                        2. Which is the best blog to read?
                      </a>
                    </div>
                    <div
                      id="collapse2"
                      className="collapse item-content-wrap"
                      data-bs-parent="#accordion"
                    >
                      <div className="card-body item-content">
                        <p>
                          When an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card single-item box-border-dark-1 border-top-0 border-start-0 border-end-0">
                    <div className="card-header item-nav">
                      <a
                        className="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-parent="#accordion"
                        href="#collapse3"
                      >
                        3. Can I switch plans?
                      </a>
                    </div>
                    <div
                      id="collapse3"
                      className="collapse item-content-wrap"
                      data-bs-parent="#accordion"
                    >
                      <div className="card-body item-content">
                        <p>
                          When an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card single-item box-border-dark-1 border-top-0 border-start-0 border-end-0">
                    <div className="card-header item-nav">
                      <a
                        className="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-parent="#accordion"
                        href="#collapse4"
                      >
                        4. What type of blogs love to read the most, and why?
                      </a>
                    </div>
                    <div
                      id="collapse4"
                      className="collapse item-content-wrap"
                      data-bs-parent="#accordion"
                    >
                      <div className="card-body item-content">
                        <p>
                          When an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card single-item box-border-dark-1 border-top-0 border-start-0 border-end-0">
                    <div className="card-header item-nav">
                      <a
                        className="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-parent="#accordion"
                        href="#collapse5"
                      >
                        5. Have more questions?
                      </a>
                    </div>
                    <div
                      id="collapse5"
                      className="collapse item-content-wrap"
                      data-bs-parent="#accordion"
                    >
                      <div className="card-body item-content">
                        <p>
                          When an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewsLetterEmail />
      </section>
    </div>
  );
};

export default contact;
